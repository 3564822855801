export const environment = {
    backend: 'https://actitool-backend.actiportal.ch',
    // backend: 'localhost:4124',
    keycloackUrl: 'https://keycloak.actiportal.ch/',
    // keycloackUrl: 'http://localhost:8081',
    clientId: 'acticom',
    // clientId: 'acticom'
    path: 'https://actiportal.ch',
    fileServer: 'https://actitool-filemanager.actiportal.ch',
    mailServer: 'https://actitool-mailmanager.actiportal.ch'
}
