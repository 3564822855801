<template>
    <div class="grid">
        <div class="col-12">
            <Toolbar class="mb-4">
                <template v-slot:start>
<!--                    <FileUpload mode="basic" choose-label="Hochladen" name="demo[]" :url="`http://localhost:4127/upload?produktId=${this.$route.params.id}`" @upload="onUpload" @before-send="beforeSend" :auto="true" accept="application/pdf" :maxFileSize="10000000" />-->
                    <FileUpload mode="basic" choose-label="Hochladen" name="demo[]" :url="`${environment.fileServer}/upload?produktId=${this.$route.params.id}`" @upload="onUpload" @before-send="beforeSend" :auto="true" accept="application/pdf" :maxFileSize="10000000" />
                </template>
            </Toolbar>
        </div>
        <div class="col-12">
            <div class="card">
                <DataTable :value="fileInfos"
                           sortMode="single" sortField="datum" :sortOrder="1" scrollable scrollHeight="400px" selectionMode="single" @rowSelect="onRowSelect" responsiveLayout="stack" breakpoint="460px">
                    <Column field="name" header="Datei">
                        <template #body="{ data }">
                            <i class="pi pi-file-pdf" style="margin-right: 4px;" />
                            {{ data.name }}
                        </template>
                    </Column>
<!--                    <Column field="url" header="URL"></Column>-->
                    <Column field="datum" header="Datum"></Column>
                    <Column field="size" header="Grösse" ></Column>
                    <Column>
                        <template #body="slotProps">
                            <Button icon="pi pi-trash" @click="deleteFile(slotProps.data)" />
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
</template>

<script>
import DataService from "@/service/DataService";
import fileService from '../service/FileService';
import store from "@/store";
import {environment} from "../../environment/environment";
export default {
    computed: {
        environment() {
            return environment
        }
    },
    data() {
        return {
            fileInfos: null,
            hide: false
        }
    },
    dataService: null,
    created() {
        this.dataService = new DataService();
    },
    async mounted() {
        this.fileInfos = await fileService.getFileInfos(this.$route.params.id);
        console.log(this.$route.params.id)
        this.hide = store.getters.getHide;
    },
    methods: {
        async onUpload() {
            this.fileInfos = await fileService.getFileInfos(this.$route.params.id)
            this.$toast.add({severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000});
        },
        async beforeSend(event){
            event.xhr.setRequestHeader('Authorization', `${store.state.token}`);
        },
        onRowSelect(event){
            fileService.downloadFile(event.data.name, this.$route.params.id);
            console.log(event.data)
        },
        async deleteFile(event){
            await fileService.deleteFile(event.name, this.$route.params.id);
            this.fileInfos = await fileService.getFileInfos(this.$route.params.id);
        }
    }
}
</script>

<style scoped lang="scss">
@import '../assets/acticom/styles/badges.scss';


::v-deep(.p-progressbar) {
  height: .5rem;
  background-color: #D8DADC;

  .p-progressbar-value {
    background-color: #607D8B;
  }
}
</style>
