import axios from 'axios';
import store from '../store/index';
import {environment} from "../../environment/environment";

const API_URL = environment.mailServer;
const api = axios.create({
    baseURL: API_URL,
});

api.interceptors.request.use((config) => {
    const authToken = store.state.token;
    if (authToken) {
        config.headers.Authorization = `${authToken}`;
    }
    return config;
});

const EmailService = {
    async sendEmail(emailData) {
        // eslint-disable-next-line no-useless-catch
        try {
            const response = await api.post('/email', { emailData: emailData} );
            return response.data;
        } catch (error) {
            throw error;
        }
    }
};

export default EmailService;

