<template>
	<div class="grid">
    <div class="col-6 mb-4">
      <div> {{standort.UNTERNEHMEN}}</div>
      <div> {{standort.BEZEICHNUNG}}</div>
      <div> {{standort.STRASSE}} {{standort.NR}}</div>
      <div> {{standort.PLZ}} {{standort.ORT}}</div>
    </div>
    <div class="col-offset-3">
      <Toolbar class="mb-4">
        <template v-slot:start>
          <div class="my-2">
            <Button label="Ändern" icon="pi pi-plus" class="p-button-success mr-2" @click="onNew" />
          </div>
        </template>
      </Toolbar>
    </div>
		<div class="col-12">
			<div class="card">
				<DataTable :value="mutationen"
                    sortMode="single" sortField="ID" :sortOrder="-1" scrollable scrollHeight="400px" selectionMode="single" responsive-layout="stack">
          <template #header>
            <h5 class="m-0">Mutationen</h5>
          </template>
                    <Column field="ID" header="Id"></Column>
                    <Column header="Zeitstempel">
                      <template #body="{data}">
                        {{formatDate(data.TS_MUTATION)}}
                      </template>
                    </Column>
                    <Column field="CREATED_BY" header="Geändert von" ></Column>
                    <Column field="ETAGE" header="Etage" ></Column>
                    <Column field="ZIMMER_NR" header="Zimmer" ></Column>
                    <Column field="STANDORT" header="Standort" ></Column>
                </DataTable>
			</div>
		</div>
	</div>

  <Dialog v-model:visible="mutationDialog" :style="{width: '450px'}" header="Produkt Details" :modal="true" class="p-fluid">
    <div class="field">
      <label for="ETAGE">Etage</label>
      <InputText id="etage" v-model.trim="produkt.ETAGE"  />
    </div>
    <div class="field">
      <label for="ZIMMER">Zimmer Nr.</label>
      <InputText id="zimmer" v-model.trim="produkt.ZIMMER_NR" />
    </div>
<!--    <div class="field">-->
<!--      <label for="STANDORT">Standort</label>-->
<!--      <InputText id="standort" v-model.trim="produkt.STANDORT" />-->
<!--    </div>-->
    <div class="field">
      <label for="standorte">Standort</label>
      <Dropdown id="standorte" v-model="produkt.STANDORT"   :options="standortOptions" :filter="true" placeholder="Standort">
        <template #value="slotProps">
          <div v-if="slotProps.value">
            {{slotProps.value}}
          </div>
          <div v-else>
            {{slotProps.placeholder}}
          </div>
        </template>
      </Dropdown>
    </div>

    <template #footer>
      <Button label="Abbrechen" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
      <Button label="Speichern" icon="pi pi-check" class="p-button-text" @click="saveMutation" />
    </template>
  </Dialog>




</template>

<script>
  import DataService from "@/service/DataService";
  import moment from "moment";
	export default {
    data() {
			return {
        mutationen: null,
        produkt: null,
        mutationDialog: false,
        mutationObj: {},
        standort: {},
        standortArr: null,
        standortOptions: []
			}
		},
    dataService: null,
		created() {
      this.dataService = new DataService();
		},
		async mounted() {
      this.mutationen = await this.dataService.getProduktHistoryById(this.$route.params.id);
      this.produkt = await this.dataService.getProdukteById(this.$route.params.id);
      this.produkt = this.produkt[0];
      this.standort = await this.dataService.getStandortById(this.produkt.FK_ID_STANDORT);
      this.standort = this.standort[0];
      this.standortArr = await this.dataService.getStandorte();
      this.standortArr.forEach( (el => {
        this.standortOptions.push(el.BEZEICHNUNG);
      }))
		},
    methods: {
      formatDate(dateString){
        return moment(dateString).format('DD.MM.YYYY HH:mm')
      },
      onNew(){
        this.mutationDialog=true;
      },
      async saveMutation(){
        await this.dataService.updateProdukte(this.produkt);
        this.$toast.add({severity:'success', summary: 'Erfolgreich', detail: 'Produkt aktualisiert', life: 3000});
        setTimeout(async()=> {this.mutationen = await this.dataService.getProduktHistoryById(this.$route.params.id);}, 100);
        this.mutationDialog = false;
      },
      async hideDialog(){
        this.mutationDialog = false;
      }
		}
	}
</script>

<style scoped lang="scss">
@import '../assets/acticom/styles/badges.scss';


::v-deep(.p-progressbar) {
	height: .5rem;
	background-color: #D8DADC;

	.p-progressbar-value {
		background-color: #607D8B;
	}
}
</style>
