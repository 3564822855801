<template>
  <div></div>

</template>

<script>
export default {
  name: "Einstellungen",
  created() {
    let url = 'https://keycloak.actiportal.ch/realms/master/account/'

    window.location.href = `${url}`;
    // window.open(url)
  },
}
</script>

<style scoped>

</style>