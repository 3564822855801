
import axios from 'axios';
import store from '../store/index';
import { environment } from "../../environment/environment";


const API_URL = environment.fileServer;


const api = axios.create({
    baseURL: API_URL,
});

api.interceptors.request.use((config) => {
    const authToken = store.state.token;
    if (authToken) {
        config.headers.Authorization = `${authToken}`;
    }
    return config;
});

export default {
    async downloadFile(fileName, produktId) {
        // eslint-disable-next-line no-useless-catch
        try {
            const response = await api.get(`/files/${fileName}`, {
                params: { produktId },
                responseType: 'blob',
            });

            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const url = window.URL.createObjectURL(blob);

            const downloadLink = document.createElement('a');
            downloadLink.href = url;
            downloadLink.download = fileName;
            downloadLink.style.display = 'none';
            document.body.appendChild(downloadLink);
            downloadLink.click();

            window.URL.revokeObjectURL(url);
            document.body.removeChild(downloadLink);
        } catch (error) {
            throw error;
        }
    },

    async deleteFile(fileName, produktId) {
        // eslint-disable-next-line no-useless-catch
        try {
            await api.delete(`/files/${fileName}`, {
                params: { produktId },
            });
        } catch (error) {
            throw error;
        }
    },

    async deleteFolder(produktId) {
        // eslint-disable-next-line no-useless-catch
        try {
            await api.delete(`/files/folder/${produktId}`);
        } catch (error) {
            throw error;
        }
    },

    async getFileInfos(produktId) {
        // eslint-disable-next-line no-useless-catch
        try {
            const response = await api.get('/files', {
                params: { produktId },
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },
};

