<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
        <p class="text-xm">Mitteilungen</p>
				<DataTable :value="ereignisse" :rowClass="rowClass" :paginator="true" :rows="20" class="text-xs"  rowGroupMode="subheader" groupRowsBy="DATE_MUTATION"
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                   currentPageReportTemplate="{first} bis {last} von {totalRecords} Mitteilungen"
                    sortMode="single" sortField="TS_MUTATION" :sortOrder="-1" scrollable scrollHeight="400px" selectionMode="single" @rowSelect="onRowSelect($event)" responsive-layout="stack">
                    <Column style="max-width:70px">
                      <template #body="slotProps">
                        {{formatTime(slotProps.data.TS_MUTATION)}}
                      </template>
                    </Column>
                    <Column field="MITTEILUNG" ></Column>
                    <template #groupheader="slotProps">
                      <span class="font-bold text-pink-800">{{formatDate(slotProps.data.DATE_MUTATION)}}</span>
                    </template>
                </DataTable>
			</div>
		</div>
	</div>


</template>

<script>
  import DataService from "@/service/DataService";
  import moment from "moment";
	export default {
    emits: ['reperatur'],
    data() {
			return {
        ereignisse: null,
        reperaturenObj: {},
        produktId: null,
			}
		},
    dataService: null,
		created() {
      this.dataService = new DataService();
		},
		async mounted() {

      this.ereignisse = await this.dataService.getEreignisse();
		},
    methods: {
      formatDate(dateString){
        return moment(dateString).format('DD.MM.YYYY')
      },
      formatTime(dateString){
        return moment(dateString).format('HH:mm')
      },
      onRowSelect(event){
        console.log(event.data)
        this.$router.push(`/produktdashboard/${event.data.FK_ID_PRODUKT}`)
      },
      rowClass(data) {
        return data.GELESEN === false ? 'font-bold': null;
      },
		}
	}
</script>

<style scoped lang="scss">
@import '../assets/acticom/styles/badges.scss';


::v-deep(.p-progressbar) {
	height: .5rem;
	background-color: #D8DADC;

	.p-progressbar-value {
		background-color: #607D8B;
	}

}
</style>
